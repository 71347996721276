import { Button } from 'primereact/button';
var numeral = require('numeral');

const Producto = ({item, retroceder, buscar}) => {

    const enlaceBuscador = (campo, textoitem) => {
        let separador = ',';
        if(textoitem.indexOf('+')){
            separador = '+';
        }
        return textoitem.split(separador).map((t,i) => 
            <a className="enlacebusquedapalabra" key={i} href="#" onClick={()=>buscar(campo,t.trim())}>{t.trim()}</a>
        ).reduce((accu, elem) => {
            return accu === null ? [elem] : [...accu, ' '+separador+' ', elem]
        }, null);
    }
    return (
        <div>
            <div className="cardHeader">
                <h3 className="card-title tituloproducto">
                 {item.nombre}
                </h3>
                <Button icon="pi pi-times" className="btn btn-secondary btnClose" onClick={()=>{
	                retroceder();
                }}/>
            </div>
            <div className="cardBody">
                <div className="mb-0">
                    <div className="table-responsive">
                        <table className="table mb-0 table-bordered-0">
                            <tbody>
                                {item.patologia && <tr>
                                    <td className="font-weight-semibold px-0">Patología</td>
                                    <td className="px-0">{enlaceBuscador("patologia",item.patologia)}</td>
                                </tr>}
                                {item.indicacion && <tr>
                                    <td className="font-weight-semibold px-0">Indicación</td>
                                    <td className="px-0">{item.indicacion}</td>
                                </tr>}
                                {item.principio && <tr>
                                    <td className="font-weight-semibold px-0">Principio activo</td>
                                    <td className="px-0">{enlaceBuscador("principio",item.principio)}</td>
                                </tr>}
                                <tr>
                                    <td className="font-weight-semibold px-0">Laboratorio</td>
                                    <td className="px-0"><span className="badge bg-primary laboratorioproducto"> <a href="#" onClick={()=>buscar('laboratorio',item.laboratorio)}>{item.laboratorio}</a> </span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="table-responsive tablePrecios">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><span className="tituloPresentaciones">Presentaciones</span></th>
                                    <th className="text-end">PVF</th>
                                    <th className="text-end">PVP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.presentacion.map((p,i)=>{
                                    let pvp = p.pvp;
                                    let pvf = p.pvf;
                                    return (
                                        <tr key={i}><td>{p.nombre}</td><td className="text-end">S/ {numeral(pvf).format('0.00')}</td><td className="text-end">S/ {numeral(pvp).format('0.00')}</td></tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Producto