import React, { useState, useRef } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Button } from 'primereact/button';
import Resultados from './Resultados';
import Producto from './Producto';
import Filtro from './Filtro';
import InputPredictivo from './InputPredictivo';

const Buscador = () => {  
    const [texto, setTexto] = useState('');
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);
    const [verFiltro, setVerFiltro] = useState(false);
    const refResultados = useRef(null);
    const refFiltro = useRef(null);

    const buscarPredictivo = (texto) => {
        setTexto(texto);
        refResultados.current.setItemSeleccionado('');
        if(texto===""){
	        setProductoSeleccionado(null);
        }
        if(texto!==""){ 
            buscarAvanzado();
        }else{
            refResultados && refResultados.current.onCambiarVisible(false);
        }
    }

    const buscarAvanzado = () => {
	   refResultados.current.setItemSeleccionado('');
        const filtros = verFiltro?refFiltro.current.obtenerState():null;
        const where = verFiltro?{
            indicacion: filtros.indicacion,
            presentacion: filtros.presentacion,
            principio: filtros.principio,
        }:{};
        setProductoSeleccionado(null);
        refResultados && refResultados.current.onCambiarVisible(true);
        refResultados && refResultados.current.onCambiarPagina({page: 0, where: where});
    }

    const seleccionaProducto = async(item) => {

        if(item.id){
            const response = await AdminServiciosAPI.postRegistro(`busquedaProducto/${item.id}`);
            
            refResultados.current.setItemSeleccionado(item.id);
            
            if(response.ok){
                const datosp = await response.json();
                setProductoSeleccionado(datosp);
				
                //refResultados && refResultados.current.onCambiarVisible(false);
            }
        }else{
            setProductoSeleccionado(null);
        }
    }

    const retrocederProducto = () => {
        setProductoSeleccionado(null);
        refResultados.current.setItemSeleccionado('');
        //refResultados && refResultados.current.onCambiarVisible(true);
    }

    const buscarTexto = (campo,textoc) => {
        const where = {};
        where[campo]=textoc;
        setTexto('');
        refResultados.current.setItemSeleccionado('');
        setProductoSeleccionado(null);
        refResultados && refResultados && refResultados.current.onCambiarVisible(true);
        refResultados && refResultados && refResultados.current.onCambiarPagina({page: 0, where: where});
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 d-block mx-auto">
                <div className="buscadorpredictivo">
                	<div className="buscador">
                		<div className="buscador_in">
							<img src="./img/icons-search.svg" className="icon-search" />
							<InputPredictivo funcionCompletado={buscarPredictivo}/>
							<div onClick={()=>buscarAvanzado()} className="btn-buscar">Buscar</div>
						</div>
						<div className="filter_content">
                        <div className="col">
                            <Button label={verFiltro?"Cerrar filtro avanzado":"Ver filtro avanzado"} icon={verFiltro?"pi pi-chevron-circle-down":"pi pi-chevron-circle-right"} className="p-button-link linkfiltroavanzado" onClick={()=>setVerFiltro(!verFiltro)} />
                            {verFiltro && <Filtro ref={refFiltro}/>}
                        </div>
                    </div>
					</div>
                    
                </div>
                <div className="searchBlock">
                	<div className="leftBlock">
                		<Resultados ref={refResultados} texto={texto} seleccion={seleccionaProducto} buscar={buscarTexto}/>
                	</div>
                	<div className="rightBlock">
                		{productoSeleccionado && <Producto item={productoSeleccionado} retroceder={retrocederProducto} buscar={buscarTexto}/>}
                	</div>
                	
                </div>
                
            </div>
        </div>
    )
}

export default Buscador 