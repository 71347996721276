import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';
import PrimeReact from 'primereact/api';
import Layout from './core/Layout';
PrimeReact.ripple = true;

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
