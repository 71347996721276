import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';

const InputPredictivo = ({funcionCompletado}) => {
    const [query, setQuery] = useState("");
  
    useEffect(() => {
      const timeOutId = setTimeout(() => funcionCompletado(query), 500);
      return () => clearTimeout(timeOutId);
    }, [query]);
  
    return (
	    
        <InputText
          placeholder="....ingrese su búsqueda"
          value={query}
          onChange={event => setQuery(event.target.value)}
          onKeyPress={(e) => {e.key === 'Enter' && funcionCompletado(query);}}
        />
    );
  }

  export default InputPredictivo