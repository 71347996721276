
const ItemDatos = ({item,buscar}) => {
    return (
        <div className="item_datos">
            <span className="text-dark">
                
                <div className="detalleItem">
                	<span><b>{item.nombre} </b> </span> 
                	<span className="badge bg-primary" onClick={()=>{
	                	buscar('laboratorio',item.laboratorio)
                	}}> {item.laboratorio} </span> 
                	{item.principio && <span> | {item.principio} </span> } 
                </div>
            </span>
            <div className="mt-0 d-block item_datos_content">
            	<div>
                {item.patologia && <p className="mb-0 leading-tight"><span className="font-weight-semibold text-dark"> Patología : </span>  {item.patologia}</p>}
                {item.indicacion && <p className="mb-0 leading-tight"><span className="font-weight-semibold text-dark"> Indicación : </span>  {item.indicacion}</p>}
                </div>
                
            </div>
             <div className="ver_precios">Ver precios</div>
            
        </div>
    )
}
/*{seleccion && <div className="col-md-3 d-none d-sm-block"><Button label="Más detalles" className="p-button-sm" onClick={()=>seleccion(item)} /></div>}*/
const ItemBusqueda = ({item, seleccion,itemSeleccionado,buscar}) => {
    //var className = "itembusquedapredictiva itemBusqueda "+(itemSeleccionado==item.id)?"itemSeleccionado":"";
    var _className = "itembusquedapredictiva itemBusqueda";
    if(Number(itemSeleccionado)===Number(item.id)){
	    _className = "itembusquedapredictiva itemBusqueda itemSeleccionado";
    }
    return (
        <div className={_className}>
            <div className="card-body">
                <div className="item-card9">
                    {seleccion && <div className="row" onClick={()=>seleccion(item)}>
                        <div className="col-md-12 col-sm-12">
                            <ItemDatos item={item} buscar={buscar}/>
                        </div>
                    </div>}
                    {!seleccion && <ItemDatos item={item}/>}
                </div>
            </div>
        </div>
    );
}

export default ItemBusqueda;