import { request } from'./Omni';
import { Config } from '../comun';

const AdminServiciosAPI = {
    getRegistro: async (parametro) => {
      const _url = `${Config.apiGateway}/${parametro}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      });
    },
    postRegistro: async (tabla,parametros) => {
      const _url = `${Config.apiGateway}/${tabla}`;
      return request(_url, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(parametros),
      });
    },
    search: async (tabla,parametros) => {
      const _url = `https://avanzomobil.pe/api/test/search`;
      return request(_url, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(parametros),
      });
    },
    searchProducto: async (tabla,parametros) => {
      const _url = `https://avanzomobil.pe/api/test/producto`;
      return request(_url, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(parametros),
      });
    },
    
   
    nuevoRegistro: async (cookieusuario,tabla,parametros) => {
        const _url = `${Config.apiGateway}/${tabla}`;
        return request(_url, {
            method: "PUT",
            headers: {"Content-Type": "application/json", "authorization":cookieusuario},
            body: JSON.stringify(parametros),
        });
    }
}

export default AdminServiciosAPI;