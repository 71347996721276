import React, { Component } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import ItemBusqueda from './ItemBusqueda';
import { BlockUI } from 'primereact/blockui';
import { Paginator } from 'primereact/paginator';


class Resultados extends Component{
    constructor(props){
        super(props);
        this.state = {
            cargando: false,
            productos: [],
            pagina: 1,
            limit: 10,
            itemSeleccionado:"",
            totalProductos: 0,
            visible: false,
            where: null
        }
        this.busquedaAvanzada = this.busquedaAvanzada.bind(this);
        this.onCambiarPagina = this.onCambiarPagina.bind(this);
        this.onCambiarVisible = this.onCambiarVisible.bind(this);
    }

    async busquedaAvanzada(){
        this.setState({cargando: true});
        const parametros = {
            pagina: this.state.pagina,
            limit: this.state.limit,
            where: this.state.where
        }
        const response = await AdminServiciosAPI.postRegistro(`busquedaWhere/${this.props.texto}`,parametros);
        if(response){
            if(response.ok){
                let resultado = await response.json();
                this.setState({ productos: resultado.productos, totalProductos: resultado.total  });
            }else{
                //const error = await response.json();
                console.log('error',response);
                this.setState({ productos: [] } );
            }
        }
        this.setState({cargando: false});
    }
	setItemSeleccionado(valor){
		this.setState({itemSeleccionado:valor});
	}
    onCambiarVisible(valor){
        this.setState({visible: valor});
    }

    onCambiarPagina(event) {
        let where=null;
        if(event.where){
            where=event.where;
        }
        this.setState({
            pagina: event.page+1,
            limit: event.rows?event.rows:this.state.limit,
            where: where?where:this.state.where
        },this.busquedaAvanzada);
    }
	/*<React.Fragment>
                        <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Número de productos por página: </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
                    </React.Fragment>*/
    render(){
        const template = {
            layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
            'RowsPerPageDropdown': (options) => {
                return (
                    <></>
                );
            },
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            }
        };
        
        var className = "";
        if(this.state.itemSeleccionado !== ""){
	        className="div_resultados"
        }

        return(
            <div style={{ display: this.state.visible ? "block" : "none" }} className={className}>
                <BlockUI blocked={this.state.cargando}>
                    {this.state.productos.map((p,i)=>{
                        return <ItemBusqueda item={p} key={i} buscar={(a,b)=>{
	                        this.props.buscar(a,b);
                        }} seleccion={this.props.seleccion} itemSeleccionado={this.state.itemSeleccionado}/>
                    })}
                    {this.state.totalProductos-this.state.productos.length>0 && <Paginator template={template} first={(this.state.pagina-1)*this.state.limit} rows={this.state.limit} totalRecords={this.state.totalProductos} onPageChange={this.onCambiarPagina} className="p-jc-end p-my-3"></Paginator>}
                </BlockUI>
            </div>
        );
    }
}

export default Resultados