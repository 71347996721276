import React from 'react';
import Buscador from '../busqueda/Buscador';

const Layout = () => {  
    return (
        <div className="container-fluid">
            <div className="container">
                <Buscador />
            </div>
        </div>
    )
}

export default Layout 