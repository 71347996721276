import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';

class Filtro extends Component {

    constructor(props) {
        super(props);
        this.state = {
            principio: '',
            indicacion: '',
            presentacion: '',
        };

        this.obtenerState = this.obtenerState.bind(this);
    }

    obtenerState(){
        return this.state;
    }

    render(){
        return (
            <div className="filtroContent">
                <div className="input-group">
                    <span className="input-group-text">Indicación</span>
                    <InputText className="form-control" value={this.state.indicacion} onChange={(e) => this.setState({indicacion: e.target.value})} placeholder="Indicación" />
                </div>
                <div className="input-group mt-2">
                    <span className="input-group-text">Presentación</span>
                    <InputText className="form-control" value={this.state.presentacion} onChange={(e) => this.setState({presentacion: e.target.value})} placeholder="Presentación" />
                </div>
                <div className="input-group mt-2">
                    <span className="input-group-text">Principio activo</span>
                    <InputText className="form-control" value={this.state.principio} onChange={(e) => this.setState({principio: e.target.value})} placeholder="Principio activo" />
                </div>
            </div>
        )
    }
    
}

export default Filtro